(function() {
  var delayedResizeHandler;

  delayedResizeHandler = function(callback) {
    var resizeTimer;
    resizeTimer = null;
    return function() {
      if (resizeTimer) {
        clearTimeout(resizeTimer);
      }
      return resizeTimer = setTimeout(function() {
        resizeTimer = null;
        return callback.call(this);
      }, PixelAdmin.settings.resize_delay);
    };
  };

  PixelAdmin.addInitializer(function() {
    var $ssw_point, $tsw_point, $window, _last_screen;
    _last_screen = null;
    $window = $(window);
    $ssw_point = $('<div id="small-screen-width-point" style="position:absolute;top:-10000px;width:10px;height:10px;background:#fff;"></div>');
    $tsw_point = $('<div id="tablet-screen-width-point" style="position:absolute;top:-10000px;width:10px;height:10px;background:#fff;"></div>');
    $('body').append($ssw_point).append($tsw_point);
    return $window.on('resize', delayedResizeHandler(function() {
      $window.trigger("pa.resize");
      if ($ssw_point.is(':visible')) {
        if (_last_screen !== 'small') {
          $window.trigger("pa.screen.small");
        }
        return _last_screen = 'small';
      } else if ($tsw_point.is(':visible')) {
        if (_last_screen !== 'tablet') {
          $window.trigger("pa.screen.tablet");
        }
        return _last_screen = 'tablet';
      } else {
        if (_last_screen !== 'desktop') {
          $window.trigger("pa.screen.desktop");
        }
        return _last_screen = 'desktop';
      }
    }));
  });

}).call(this);
